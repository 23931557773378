











































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { Md5 } from "ts-md5";
import {
  GetCurrentUserData,
  PostChangeAccountName,
  PostChangePassword,
  PostChangePhone,
  PostPhoneCode,
  LoginOut,
} from "@/request/account";
import { validatePhoneNumber } from "@/utils/validator";
import { countDownTime } from "@/utils/count-down-time";
@Component({})
export default class Name extends Vue {
  private user: any = { role: [] };
  private ifShowChangeAccountName: boolean = false;
  private newAccountName: any = "";
  private ifShowChangePassword: any = false;
  private oldPassword: any = "";
  private newPassword: any = "";
  private renewPassword: any = "";
  private ifShowChangePhone: boolean = false;
  private phoneCode: any = "";
  private newPhone: any = "";
  private newRePhone: any = "";
  private waitTime: number = 60;
  /**
   * @description 获取用户信息
   */
  private fetchUserData(): void {
    GetCurrentUserData(this).then((data: any) => {
      this.user = data;
      this.$store.commit("updateUser", data);
    });
  }
  /**
   * @description 开始修改账户名
   */
  private startChangeAccountName(): void {
    this.ifShowChangeAccountName = true;
    this.newAccountName = "";
  }
  /**
   * @description 开始修改密码
   */
  private startChangePassword(): void {
    this.ifShowChangePassword = true;
    this.newPassword = "";
    this.renewPassword = "";
  }
  /**
   * @description 开始修改手机号码
   */
  private startChangePhone(): void {
    this.ifShowChangePhone = true;
    this.phoneCode = "";
    this.newPhone = "";
    this.newRePhone = "";
    this.waitTime = 0;
  }
  /**
   * @description 确认修改账户名
   */
  private confirmChangeAccountName(): void {
    const params = {
      新用户名: this.newAccountName,
    };
    PostChangeAccountName(this, params).then(() => {
      this.newAccountName = "";
      this.ifShowChangeAccountName = false;
      this.fetchUserData();
      this.$message.success("修改成功");
    });
  }
  /**
   * @description 确定修改密码
   */
  private confirmChangePassword(): void {
    // const reg =
    //   /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[!@#$%^&*\_\-?.])[a-zA-Z\d!#@*&.]{8,}$/;
    const reg =
      /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[!~@#$%^&*\_\-?.])[a-zA-Z\d!~@#$%^&*.]{8,}$/;
    if (!this.oldPassword) {
      this.$message.warning("旧密码不能为空");
      return;
    }
    if (!this.newPassword) {
      this.$message.warning("新密码不能为空");
      return;
    }
    if (!this.renewPassword) {
      this.$message.warning("重复新密码不能为空");
      return;
    }
    if (!reg.test(this.oldPassword)) {
      this.$message.warning(
        "旧密码长度必须8位以上，且包含英文大小写和数字以及特殊字符"
      );
      return;
    }
    if (!reg.test(this.newPassword)) {
      this.$message.warning(
        "新密码长度必须8位以上，且包含英文大小写和数字以及特殊字符"
      );
      return;
    }
    if (this.newPassword !== this.renewPassword) {
      this.$message.warning("两次输入的密码不一致，请重新输入");
      // this.newPassword = "";
      // this.renewPassword = "";
      return;
    }
    console.log(this.oldPassword);
    const Base64 = require("js-base64").Base64;
    const params = {
      // 旧密码: Base64.encode(this.oldPassword),
      // 新密码: Base64.encode(this.newPassword),
      旧密码: Md5.hashStr("qihuang" + this.oldPassword),
      新密码: Md5.hashStr("qihuang" + this.newPassword),
    };
    PostChangePassword(this, params).then(() => {
      this.$message.success("修改成功");
      this.newPassword = "";
      this.renewPassword = "";
      this.ifShowChangePassword = false;
      this.logout();
    });
  }
  /**
   * @description 退出登录
   */
  private logout(): void {
    LoginOut(this).then((res) => {
      localStorage.removeItem("token");
      this.$router.push("/access/login");
    });
  }
  /**
   * @description 确认修改手机
   */
  private confirmChangePhone(): void {
    const rep = /^\d{11}$/;
    const shoujiRep = rep.test(this.newPhone);
    if (!shoujiRep) {
      this.$message.error("手机号码格式错误！");
      return;
    }
    // if (this.newPhone !== this.newRePhone) {
    //   this.$message.warning("两次输入的手机号不一致，请重新输入");
    //   return;
    // }
    // const reg =
    //   /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[!@#$%^&*\_\-?.])[a-zA-Z\d!#@*&.]{8,}$/;
    // const passwordReg = reg.test(this.newPassword);
    // // console.log(reg.test("As#1234567"));
    // if (!passwordReg) {
    //   this.$message.warning(
    //     "密码长度必须8位以上，且包含英文大小写和数字以及特殊字符"
    //   );
    //   return;
    // }
    const params = {
      新手机号: this.newPhone,
      验证码: this.phoneCode,
    };
    PostChangePhone(this, params)
      .then(() => {
        this.phoneCode = "";
        this.newPhone = "";
        this.newRePhone = "";
        this.ifShowChangePhone = false;
        this.fetchUserData();
        this.$message.success("修改成功");
      })
      .catch(() => {
        this.phoneCode = "";
      });
  }
  /**
   * @description 获得验证码
   */
  private getCode(): void {
    const rep = /^\d{11}$/;
    const shoujiRep = rep.test(this.newPhone);
    if (!shoujiRep) {
      this.$message.error("手机号码格式错误！");
      return;
    }
    const { valide, message } = validatePhoneNumber(this.newPhone);
    if (!valide) {
      this.$message.error(message);
    } else {
      PostPhoneCode(this, this.newPhone).then(() => {
        this.$message.success("发送成功");
        this.waitTime = 60;
        localStorage.setItem("waitTime", this.waitTime.toString());
        countDownTime(this, this.waitTime, "waitTime");
      });
    }
  }
  private mounted() {
    this.waitTime = Number(localStorage.getItem("waitTime") || "0");
    if (this.waitTime !== 0) {
      countDownTime(this, this.waitTime, "waitTime");
    }
    this.fetchUserData();
  }
}
